import { Box, Link } from '@mui/material';
import { useCallback } from 'react';
import { useLoginMutation, useRegisterMutation } from '../../../plugins/gatsby-plugin-redux/store/api/auth.api';
import ErrorAlert from '../../components/app/ErrorAlert';
import LoadingButton from '../../components/app/LoadingButton';
import AuthLayout from '../../components/pages/auth/AuthLayout';
import Form from '../../field/Form';
import InputField from '../../field/InputField';
import InputPasswordField from '../../field/InputPasswordField';
import handleSubmitAction from '../../helpers/handleSubmitAction';
import { isEmail, isMin, isRequired } from '../../helpers/validators';
import useAuthRedirect from '../../hooks/useAuthRedirect';
import { SubmitHandler } from '../../types/app';
import { SignupFormData } from '../../types/form';

const Signup = () => {
  useAuthRedirect();

  const [registerAction] = useRegisterMutation();
  const [loginAction] = useLoginMutation();

  const handleSignup = useCallback<SubmitHandler<SignupFormData>>(async (values) => {
    await registerAction(values).unwrap();
    await loginAction({ identifier: values.email, password: values.password }).unwrap();
  }, [loginAction, registerAction]);

  return (
    <AuthLayout>
      <Form<SignupFormData>
        initialValues={{
          email: '',
          password: '',
          username: '',
          firstName: '',
          lastName: '',
        }}
        onSubmit={handleSubmitAction(handleSignup)}
      >
        {(config) => (
          <form onSubmit={config.handleSubmit}>
            <ErrorAlert message={config.errors._server?.message} />
            <InputField name="firstName" fullWidth label="First Name" autoComplete="firstName" rules={[isRequired]} />
            <InputField name="lastName" fullWidth label="Last Name" autoComplete="lastName" rules={[isRequired]} />
            <InputField name="email" fullWidth label="Email" autoComplete="email" rules={[isRequired, isEmail]} />
            <InputField name="username" fullWidth label="Username" autoComplete="username" rules={[isRequired]} />
            <InputPasswordField name="password" type="password" fullWidth label="Password" autoComplete="password" rules={[isRequired, isMin(6)]} />
            <LoadingButton type="submit" fullWidth loading={config.isLoading}>Submit</LoadingButton>

            <Box textAlign="center" marginY={2}>
              <Link href="/auth/signin">Have an account? Log in</Link>
            </Box>
          </form>
        )}
      </Form>
    </AuthLayout>
  );
};

export default Signup;
